import ImageContentTab from "@components/ImageContentTab/ImageContentTab"
import React, { useState } from "react"
import { mapToTabControlList } from "./mapper"
import { ImageContentListItemComponentProps } from "./types"
import { mapToPlateFormatList } from "@mappers/index"
import { CombinationPricingList } from "@components/CombinationPricingOptions/CombinationPricingList"
import { FaqContainer, FaqContent, FaqIcon, FaqTitle } from "@components/FaqList/FaqList.styles"
import { mapToFaqItem } from "@components/FaqList/mapper"

export const ImageContentListItemComponent = (
  props: ImageContentListItemComponentProps
) => {
  const { item } = props
  const [activeIndex, setActiveIndex] = useState(-1)

  const handleClick = (event: any, titleProps: { index: number }) => {
    const { index } = titleProps   
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }

  if (item.linked_items.length > 0) {
    switch (item.linked_items[0].__typename) {
      case "KontentItemContentlist":
        const tabData = mapToTabControlList(item.linked_items[0].elements)
        return (
          <ImageContentTab
            backgroundColor={props.backgroundColor}
            data={tabData}
          />
        )
      case "KontentItemListContainer":
        const listData = mapToPlateFormatList(
          item.linked_items[0].elements.items
        )
        const bottomText = item.linked_items[0].elements.bottom_text.value
        return (
          <CombinationPricingList
            combinationFormats={listData}
            bottomText={bottomText}
          />
        )
      case "KontentItemFaqItem":
        const itemData = mapToFaqItem(
          item.linked_items[0].elements
        )
        return (
          <FaqContainer
                borderColor="#eee"
                backgroundColor="white"
                key={0}
              >
                <FaqTitle
                  color="black"
                  backgroundColor="white"
                  active={activeIndex === 0} index={0}
                  onClick={handleClick}
                  style={{textAlign:"left", fontSize:"14px", fontWeight:"bold"}}
                >
                  <FaqIcon
                    color="#F3921B"
                    name={activeIndex === 0 ? "minus" : "plus"}
                  />
                  {itemData.title}
                </FaqTitle>
                <FaqContent
                  color="black"
                  backgroundColor="white"
                  active={activeIndex === 0}
                  style={{textAlign:"left", fontSize:"14px", fontWeight:'500'}}
                >
                  <div
                    id="___gatsby"
                    dangerouslySetInnerHTML={{ __html: itemData.content }}
                  />
                </FaqContent>
              </FaqContainer>
        )
      default:
        return <div></div>
    }
  } else {
    return <div></div>
  }
}
