import React from "react"
import styled from "@emotion/styled"
import { Container, Grid, ListItem, Segment } from "semantic-ui-react"
import mq from "@styles/mq"
import { H1, H2, Paragraph1 } from "@styles/Global.styles"

export const ImageContentSegment = styled(({ bgColor, padding, ...rest }) => (
  <Segment {...rest} />
))`
  &&&& {
    border: none;
    box-shadow: none;
    border-radius: 0px;
    padding-top: ${props => props.paddingTop}px;
    padding-bottom: ${props => props.paddingBottom}px;
    background-color: ${props => props.bgColor};
    ${props =>
      mq({
        paddingTop: [
          props.padding.mobile.top,
          props.padding.tablet.top,
          props.padding.desktop.top,
        ],
        paddingBottom: [
          props.padding.mobile.bottom,
          props.padding.tablet.bottom,
          props.padding.desktop.bottom,
        ],
        paddingLeft:['0px !important;', '14px !important;','14px !important;'],
        paddingRight:['0px !important;', '14px !important;','14px !important;']
      })}
  }
`

export const ImageContentTitle = styled(H1)`
  color: rgb(61, 174, 43);
  text-align: left;
`

export const ImageContentSubTitle = styled(H2)`
  text-align: left;
  margin-bottom: 0px;
  img {
      margin-right: 8px;
  }
  ${mq({
    fontSize: ['18px','24px','24px']
})}
`
export const ImageContentDescription = styled(Paragraph1)`
  &&&& {
    text-align: left;
    margin-bottom: 0px;
    font-weight: 500;
    color: #707070;
    a {
      color: #3DAE2B;
      
    }
  }
`

export const ImageContentListItemStyle = styled(ListItem)`
  &&&& {
    .ui.list {
      margin: 0em 0em;
    }
  }
`
export const ImageContentLinkStyle = styled(({ ...others }) => (
  <a {...others} />
))`
  color: #212121;
  text-decoration: underline;
  :hover {
    color: #212121;
  }
`
export const GridLeftColumnStyle = styled(Grid.Column)`
  &&&& {
    max-width: 480px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
    ${mq({
      marginRight: ['0px','16px','16px'],
      paddingTop:['0px !important','14px','14px'],
      marginTop:['-14px !important','0px !important','0px !important']
    })};
    img {      
      height:100%;
      width:100%;
    }
  }
`
export const GridRightColumnStyle = styled(Grid.Column)`
  &&&& {
    margin: auto 20px;
    text-align: left;
    max-width: 480px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
    ${mq({
      marginLeft: ['0px','16px','16px']
    })}
  }
`
export const GiftRow = styled(Grid.Row)`
  border: #eee solid 2px;  
  padding: 24px !important;
  justify-content: normal !important;
  border-radius:4px;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12);
`
export const GiftTopRow = styled(Grid.Row)`
  padding: 0px !important;
  justify-content: space-between !important;
  margin-bottom:16px;
`
export const GiftGrid = styled(Grid)` 
    
  ${mq({
    width: ['fit-content !important;','fit-content !important;','fit-content !important;']
})}
`
export const GiftContainer = styled(Container)`
    justify-content: center !important;
    display: flex !important;
`