import { KpButton } from "@elements/index"
import styled from "@emotion/styled"
import { H2, Paragraph1 } from "@styles/Global.styles"
import mq from "@styles/mq"
import React from "react"
import { Container, Grid, List } from "semantic-ui-react"

export const ImageContentListStyle = styled(List)`
  &&&& {
    width: 100%;
    margin: 0em auto;
    max-width: 1140px;
    
  }
  ${mq({ 
    paddingLeft: ['0px !important;', '14px !important;', '14px !important;'],
    paddingRight: ['0px !important;', '14px !important;', '14px !important;'],
  })}
`
export const GenericContainer = styled(({ padding, ...rest }) => (
  <Container {...rest} />
))`
  &&&& {
    ${props =>
      mq({
        paddingTop: [
          props.padding.mobile.top,
          props.padding.tablet.top,
          props.padding.desktop.top,
        ],
        paddingBottom: [
          props.padding.mobile.bottom,
          props.padding.tablet.bottom,
          props.padding.desktop.bottom,
        ],
      })}
    text-align: center;
    padding-top: ${prop => prop.paddingTop};
    padding-bottom: ${prop => prop.paddingBottom};
  }
`
export const ImageContainerTopDescirptionStyle = styled(Paragraph1)`
  p{
    font-weight: 500;
    ${mq({ 
      fontSize: ['14px;', '16px;', '16px;']
    })}
    color: #707070;
  }
  
`
export const ImageContainerTopTitle = styled(H2)`      
  ${mq({ 
    fontSize: ['24px;', '30px;', '36px;'],
  })};
  margin-bottom:8px;
`
export const ImageContainer = styled.div`
  text-align: center;
  ${mq({marginBottom:['30px', '24px', '24px']})}
`
export const FirstItemRow = styled(Grid.Row)`
  margin-top: 30px;
  max-width: 1140px;
  margin-left: auto;
  margin-right:auto;
  ${mq({ 
    paddingLeft: ['0px !important;', '14px !important;', '14px !important;'],
    paddingBottom: ['0px !important;','24px !important;','24px !important;']
  })};
`
export const FirstItemLeftColumn = styled(Grid.Column)`
  ${mq({ 
    paddingRight: ['0px !important;', '25px !important;', '46px !important;']
  })};
`
export const FirstItemRightColumn = styled(Grid.Column)`
   ${mq({ 
     marginTop: ['0px !important;', 'auto !important;', 'auto !important;'],
     marginBottom:['0px !important;', 'auto !important;', 'auto !important;'],
     paddingLeft: ['0px !important;', '25px !important;', '46px !important;']
   })};

`
export const ButtonContainer = styled.div`
  padding: 20px 0px 48px 0px;
  text-align: center;
  ${mq({ 
    marginLeft: ['0px !important;', '1em !important;', '1em !important;']    
  })};
  
  #create_gift_btn {
    ${mq({ 
      width: ['100%;', 'auto;', 'auto;'],      
    })};
  }
    #create_gift_btn > button{
      ${mq({ 
        width: ['100%;', 'auto;', 'auto;'],
        margin: ['0px 0px', '0px 40px', '0px 40px']
      })};
    }
`
export const NavigationMenuButton = styled(({darkTheme, ...otherProps}) => <KpButton {...otherProps}/>)`
    &&&&& {
        margin: 0 40px;
        padding-left: 25px;
        padding-right: 25px;
        min-width: 0px;
        :hover{
            background-color: ${p => p.darkTheme ? 'white' : 'black'};
            color: ${p => p.darkTheme ? 'black' : 'white'};
            border-color: ${p => p.darkTheme ? 'white' : 'black'};
        }        
    }    
`