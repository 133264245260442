import {
  ImageContentListProps,
  ImageTabControlProps,
  IKontentDictionary,
  ImageContentListItemType,
} from "./types"

export const mapToImageContentList = (data: ImageContentListProps) => {
  const { title, description, listitem } = data

  return {
    title: title.value,
    description: description.value,
    listitem: listitem.linked_items,
  }
}

export const mapToImageContentItem = (data: any, index: number) => {
  const { title, description, image, components, icon } = data

  const element: ImageContentListItemType = {
    image: image.value,
    title: title.value,
    description: description.value,
    index: index,
    components: components,
    icon: icon.value
  }
  return element
}

export const mapToImageTabControl = (data: ImageTabControlProps) => {
  const { title, content } = data
  const result: IKontentDictionary = {
    header: title.value,
    content: content.value,
  }
  return result
}

export const mapToTabControlList = (elements: any) => {
  const result: IKontentDictionary[] = elements.listitems.linked_items.map(
    (item: { elements: ImageTabControlProps }) => {
      return mapToImageTabControl(item.elements)
    }
  )
  return result
}
