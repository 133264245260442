import React from "react"
import { ImageContentListItemProps } from "./types"
import { Container, Grid } from "semantic-ui-react"
import {
  ImageContentSegment,
  ImageContentTitle,
  ImageContentSubTitle,
  ImageContentDescription,
  GridLeftColumnStyle,
  GridRightColumnStyle,
  GiftRow,
} from "./ImageContentListItem.styles"
import { ImageContentListItemComponent } from "./ImageContentListItemComponent"

class ImagecontentListItem extends React.Component<ImageContentListItemProps> {
  constructor(props: ImageContentListItemProps) {
    super(props)
  }

  getInnerText = (description: string): string => {
    let descriptionInner = description
      .replaceAll("<br>", "")
      .replaceAll("<p>", "")
      .replaceAll("</p>", "")
    return descriptionInner
  }

  render = () => {
    const { image, title, description, index, components, icon } = this.props.item

    return (
      <ImageContentSegment
        bgColor={this.props.backgroundColor}
        padding={{
          mobile: {
            top: 20,
            bottom: 20,
          },
          tablet: {
            top: 24,
            bottom: 24,
          },
          desktop: {
            top: 24,
            bottom: 24,
          },
        }}
        id={"step" + (index + 1)}
      >
        <Container textAlign="center">
          <Grid stackable columns={2} textAlign="center">
            <GiftRow>              
              <GridLeftColumnStyle width={6}>
                <img src={image[0].url} alt={image[0].description} />
              </GridLeftColumnStyle>
              <GridRightColumnStyle width={8}>                
                <ImageContentSubTitle><img src={icon[0].url} alt={icon[0].description} />{title}</ImageContentSubTitle>
                {this.getInnerText(description) == "" ? (
                  ""
                ) : (
                  <ImageContentDescription
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                )}

                <ImageContentListItemComponent
                  item={components}
                  backgroundColor={this.props.backgroundColor}
                />
              </GridRightColumnStyle>              
            </GiftRow>
          </Grid>
        </Container>
      </ImageContentSegment>
    )
  }
}

export default ImagecontentListItem
