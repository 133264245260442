import React, { useState } from "react"
import { graphql } from "gatsby"
import { Layout } from "@components/Layout"
import { Banner, mapToPageHeader } from "@components/Banner"
import { SEO, mapToSEO } from '@components/SEO'
import { SignupBannerWrapper, mapToKiwiVIPVisible } from "@components/SignupBanner";
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import { mapToImageGridContainer } from "@mappers/ImageGrid/ImageGrid.mapper"
import { mapToImageLinkBanner } from "@components/ImageButtonBanner"
import { mapToFaqContainer } from "@components/FaqList/mapper"
import { FaqList } from "@components/FaqList"
import theme from "@styles/theme"
import { mapToCustomerReviewData } from "@components/CustomerReviewCardSlider"
import { mapToGiftingExploreDesignsData, mapToGiftingOptionsData, mapToGiftingStepsData } from "@mappers/Gifting/Gfiting.mapper"
import CompHowItWorks from "@components/HowItWorks/CompHowItWorks"
import { mapToHowItWorksItems } from "@mappers/HowItWorks/howItWorks.mapper"
import { VehicleType } from "@models/ProductModels"
import { ImageContentList } from "@components/ImageContentListGift/ImageContentList"
import { mapToImageContentList } from "@components/ImageContentListGift/mapper"
import { ButtonContainer, NavigationMenuButton } from "@components/ImageContentListGift/ImageContentList.styles"

interface PageState {
  combination: string
  vehicleType: VehicleType
  isVehicleTypeChanged: boolean
}

const GiftingLandingPage = (props: any) => {

  const { elements } = props.data.kontentItemGiftingLandingPage;
  const seo = mapToSEO(elements);
  const kiwiVip = mapToKiwiVIPVisible(elements);
  const metadata = mapToPageHeader(elements);
  const apiUrl = props.data.site.siteMetadata.apiUrl;
  const reCaptchaSiteKey = props.data.site.siteMetadata.reCaptchaSiteKey;
  const reCaptchaSiteKeyV2 = props.data.site.siteMetadata.reCaptchaSiteKeyV2;
  const imageGridContainerData = mapToImageGridContainer(elements.image_grid.linked_items[0].elements)
  const faqData = mapToFaqContainer(elements.faq_s.linked_items[0].elements)
  const imageButtonBannerData = mapToImageLinkBanner(elements.image_link_banner.linked_items[0].elements)
  let customerReviewData = mapToCustomerReviewData(elements.customer_review_banner.linked_items[0].elements, false)
  const giftingStepsData = mapToGiftingStepsData(elements.gifting_steps.linked_items[0].elements)
  const giftingOptionsData = mapToGiftingOptionsData(elements.gifting_options.linked_items[0].elements)
  const giftingExploreDesignsData = mapToGiftingExploreDesignsData(elements.explore_designs.linked_items[0].elements, props.data.site.siteMetadata.apiUrl)
  const compHowItWorksData = mapToHowItWorksItems(elements.how_it_works.linked_items[0].elements)
  const imageContentItemListData = mapToImageContentList(elements.gifted_to_perfection.linked_items[0].elements)
  const [isVoucher, setIsVoucher] = useState(false);
  const [combination, setCombination] = useState("");
  const [isVehicleTypeChanged, setIsVehicleTypeChanged] = useState(false);
  const [vehicleType, setVehicleType] = useState({
    name: 'Car',
    id: 1,
    letters: 6,
    largeCharWidth: 55,
    smallCharWidth: 27.5,
    spaceCharWidth: 13.75,
    maxChars: 6,
    maxWidth: 330
});


  return (<Layout location={props.location}>
    <SEO {...seo} />
    <Banner {...metadata} isBiggerBanner={false} />
    {/* <GenericBannerContainer
      padding={{
        mobile: {
          top: 60,
          bottom: 50,
        },
        desktop: {
          top: 30,
          bottom: 50,
        },
      }}
      backgroundColor={theme.brand.colors.beige}
    >
      <Grid stackable textAlign='center'>
            <Grid.Row centered>
                <Grid.Column width={16} textAlign='center'>
                    <H3>{elements.heading.value}</H3>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={10}>
                    <Paragraph1>
                    {elements.description.value}
                    </Paragraph1>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                <ButtonToggler>        
                  <GiftToggleButton active={isVoucher} attached='left' onClick={() => selectVoucher(true)}>Gift Voucher</GiftToggleButton>
                  <GiftToggleButton active={!isVoucher} attached='right' onClick={() => selectVoucher(false)}>Gift a Plate</GiftToggleButton>             
                </ButtonToggler>
              </Grid.Column>
            </Grid.Row>
        </Grid>
    </GenericBannerContainer> 
    {!isVoucher ? 
    <>
     <GenericBannerContainer
        padding={{
          mobile: {
            top: 30,
            bottom: 50,
          },
          desktop: {
            top: 30,
            bottom: 50,
          },
        }}
        backgroundColor={giftingStepsData.backgroundColor}>
          <GiftingStepsContainer {...giftingStepsData} />
      </GenericBannerContainer>
       <GenericBannerContainer
        padding={{
          mobile: {
            top: 30,
            bottom: 50,
          },
          desktop: {
            top: 30,
            bottom: 50,
          },
        }}
        backgroundColor={giftingOptionsData.backgroundColor}>
          <GiftingOptionsContainer {...giftingOptionsData} />
      </GenericBannerContainer> 
      <GenericBannerContainer
        padding={{
          mobile: {
            top: 30,
            bottom: 50,
          },
          desktop: {
            top: 30,
            bottom: 50,
          },
        }}
        backgroundColor={giftingExploreDesignsData.backgroundColor}>
          <GiftingExploreDesignsContainer {...giftingExploreDesignsData} />
      </GenericBannerContainer>*/}      
     
      <GenericBannerContainer
        padding={{
          mobile: {
            top: 62,
            bottom: 0,
          },
          desktop: {
            top: 32,
            bottom: 0,
          },
        }}
        backgroundColor="#fff"
      >
        <ImageContentList
          title={imageContentItemListData.title}
          description={imageContentItemListData.description}
          listitem={imageContentItemListData.listitem}
          apiUrl={apiUrl}           
          vehicleType={vehicleType} carOnly={false} reCaptchaSiteKey={reCaptchaSiteKey} 
          reCaptchaSiteKeyV2={reCaptchaSiteKeyV2}
        />
        
      <ButtonContainer>
        <NavigationMenuButton
          ignoreMinWidth={true}
          id="create_gift_btn"
          link="/create/"
          buttonType="primary"
          textColor="black"
          color="#3DAE2B"
          darkTheme={false}
        >
          CREATE A PLATE
        </NavigationMenuButton>
      </ButtonContainer>
      
      </GenericBannerContainer>
      
    {/* </> :
    <GenericBannerContainer
      padding={{
        mobile: {
          top: 30,
          bottom: 50,
        },
        desktop: {
          top: 30,
          bottom: 50,
        },
      }}
      backgroundColor={theme.brand.colors.beige}
    >
      <GiftVoucher
        RoboVoucherId="NZM8QD"
      ></GiftVoucher>
    </GenericBannerContainer>}

     <GenericBannerContainer
      padding={{
        mobile: {
          top: 38,
          bottom: 0
        },
        desktop: {
          top: 30,
          bottom: 0
        }
      }}
      backgroundColor={imageGridContainerData.backgroundColour}>
      <ImageGridContainer {...imageGridContainerData} />
    </GenericBannerContainer>
    <GenericBannerContainer padding={{ mobile: { top: 0, bottom: 0 }, desktop: { top: 0, bottom: 0 } }} backgroundColor={customerReviewData!.backgroundColor}>
      <CustomerReviewCardSlider {...customerReviewData} />
    </GenericBannerContainer> */}
    <GenericBannerContainer
        padding={{
          mobile: {
            top: 48,
            bottom: 40,
          },
          desktop: {
            top: 40,
            bottom: 0,
          },
        }}
        backgroundColor="#000"
      >
        <CompHowItWorks {...compHowItWorksData} darkTheme={true}></CompHowItWorks>
      </GenericBannerContainer>
    <GenericBannerContainer padding={{ mobile: { top: 30, bottom: 60 }, desktop: { top: 30, bottom: 100 } }} backgroundColor={faqData.backgroundColor}>
      <FaqList borderColor={theme.brand.colors.black} questionColor={theme.brand.colors.black} answerColor={theme.brand.colors.black} {...faqData} />
    </GenericBannerContainer>
    {
      kiwiVip.visible &&
      <SignupBannerWrapper version={kiwiVip.version} />
    }
  </Layout>);

}

export const query = graphql`  
{
  site {
    siteMetadata {
      apiUrl
      reCaptchaSiteKey
      reCaptchaSiteKeyV2
    }
  }
  kontentItemGiftingLandingPage {
    elements {
      faq_s {
        linked_items {
          ... on KontentItemFaqBlock {
            id
            elements {
              background_colors {
                value {
                  codename
                  name
                }
              }
              title {
                value
              }
              description{
                  value
                }
              faqs {
                linked_items {
                  ... on KontentItemFaqItem {
                    id
                    elements {
                      answer {
                        value
                      }
                      faq_category {
                        value {
                          codename
                          name
                        }
                      }
                      question {
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      general_page_content__header_image {
        value {
          url
          description
        }
      }
      general_page_content__kiwivip_signup {
        value {
          codename
          name
        }
      }
      general_page_content__kiwivip_signup_version {
        value {
          codename
          name
        }
      }
      general_page_content__title {
        value
      }
      general_page_content__title_position {
        value {
          codename
          name
        }
      }
      heading {
        value
      }
      description {
        value
      }
      subheader {
        value
      }
      subheader_description {
        value
      }
      image_link_banner {
        linked_items {
          ... on KontentItemImageLinkBanner {
            elements {
              button__button_color_theme {
                value {
                  codename
                  name
                }
              }
              button__button_type {
                value {
                  codename
                  name
                }
              }
              button__text {
                value
              }
              button__url_slug {
                value
              }
              image {
                value {
                  url
                  description
                }
              }
              title {
                value
              }
            }
          }
        }
      }
      image_grid {
        linked_items {
          ... on KontentItemImageGridContainer {
            id
            elements {
              background_colors {
                value {
                  codename
                  name
                }
              }
              heading {
                value
              }
              sub_heading {
                value
              }
              image_grid_items {
                linked_items {
                  ... on KontentItemImageGridItem {
                    id
                    elements {
                      body {
                        value
                      }
                      heading {
                        value
                      }
                      image {
                        value {
                          url
                          description
                        }
                      }
                      url {
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      seo__nofollow {
        value {
          codename
          name
        }
      }
      seo__noindex {
        value {
          codename
          name
        }
      }
      seo__page_description {
        value
      }
      seo__page_title {
        value
      }
      url {
        value
      }
      general_page_content__responsive_header_image {
        linked_items {
          ... on KontentItemBannerImage {
            id
            elements {
              desktop {
                value {
                  url
                  description
                }
              }
              mobile {
                value {
                  description
                  url
                }
              }
              tablet {
                value {
                  description
                  url
                }
              }
            }
          }
        }
      }
      customer_review_banner {
        linked_items {
          ... on KontentItemCustomerReviewCardBanner {
            elements {
              title {
                value
              }
              subtitle{
                value
              }
              title_2{
                value
              }
              rating {
                value
              }
              totalreviews {
                value
              }
              background_colors {
                value {
                  codename
                }
              }
              cards {
                linked_items {
                  ... on KontentItemCustomerReviewCard {
                    elements {
                      customer_name {
                        value
                      }
                      avatar {
                        value {
                          url
                        }
                      }
                      rating {
                        value
                      }
                      title {
                        value
                      }
                      description {
                        value
                      }
                      create_date{
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      gifting_steps {
        linked_items {
          ... on KontentItemGiftingSteps {
            elements {
              title {
                value
              }
              background_colors {
                value {
                  codename
                }
              }
              steps {
                linked_items {
                  ... on KontentItemGiftingStepItem {
                    elements {
                      title {
                        value
                      }
                      description {
                        value
                      }
                      icon {
                        value {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      gifting_options {
        linked_items {
          ... on KontentItemGiftingOptions {
            elements {
              title {
                value
              }
              description {
                value
              }
              background_colors {
                value {
                  codename
                }
              }
              gifting_options {
                linked_items {
                  ... on KontentItemGiftingOptionItem {
                    elements {
                      picture {
                        value {
                          url
                        }
                      }
                      item {
                        linked_items {
                          ... on KontentItemCheckoutSelectionCard {
                            elements {
                              heading {
                                value
                              }
                              body {
                                value
                              }
                              content{
                                value
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      explore_designs {
        linked_items {
          ... on KontentItemExploreDesigns {
            elements {
              title {
                value
              }
              background_colors {
                value {
                  codename
                }
              }
              items {
                linked_items {
                  ... on KontentItemProductCategory {
                    id
                    elements {
                      category_name {
                        value
                      }
                      lock_caption_colors {
                        value {
                          codename
                          name
                        }
                      }
                      category_description_rich {
                        value
                      }
                      vehicle_types {
                        value {
                          name
                          codename
                        }
                      }
                      new_design {
                        value {
                          codename
                          name
                        }
                      }
                      fixed_fomat_price {
                        value
                      }
                      flexi_format_price {
                        value
                      }
                      upgrade_starting_price {
                        value
                      }
                      redesign_starting_price {
                        value
                      }
                      premium_3_character_price {
                        value
                      }
                      premium_2_character_price {
                        value
                      }
                      product_designs {
                        linked_items {
                          ... on KontentItemPlateDesign {
                            elements {
                              name {
                                value
                              }
                              dealer_only_design {
                                value {
                                  name
                                  codename
                                }
                              }
                              disable_dual_sizing {
                                value {
                                  codename
                                  name
                                }
                              }
                              authorized_dealers {
                                linked_items {
                                  ... on KontentItemAuthorizedDealers {
                                    elements {
                                      title {
                                        value
                                      }
                                      link_message {
                                        value
                                      }
                                      description {
                                        value
                                      }
                                      dealer_contacts {
                                        linked_items {
                                          ... on KontentItemDealerContact {
                                            elements {
                                              dealer_name {
                                                value
                                              }
                                              contact_name {
                                                value
                                              }
                                              phone {
                                                value
                                              }
                                              formatted_phone_number {
                                                value
                                              }
                                              dealer_id {
                                                value
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              transaction_types {
                                value {
                                  name
                                  codename
                                }
                              }
                              vehicle_types {
                                value {
                                  name
                                  codename
                                }
                              }
                              kpi_plate_design_code {
                                value {
                                  name
                                  codename
                                }
                              }
                              kpi_plate_foreground_color {
                                value {
                                  name
                                  codename
                                }
                              }
                              plate_size {
                                value {
                                  name
                                  codename
                                }
                              }
                              plate_color {
                                value {
                                  name
                                  codename
                                }
                              }
                              base_price {
                                value
                              }
                              redesign_price {
                                value
                              }
                              govt_upgrade_price {
                                value
                              }
                              captions {
                                value {
                                  name
                                  codename
                                }
                              }
                              max_message_length__mm_ {
                                value
                              }
                              bottom_caption_max_length__mm_ {
                                value
                              }
                              message_colors {
                                value {
                                  name
                                  codename
                                }
                              }
                              european_brand_name {
                                value {
                                  name
                                  codename
                                }
                              }
                              dual_size_plate {
                                linked_items {
                                  ... on KontentItemPlateDesign {
                                    elements {
                                      name {
                                        value
                                      }
                                      dealer_only_design {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      disable_dual_sizing {
                                        value {
                                          codename
                                          name
                                        }
                                      }
                                      authorized_dealers {
                                        linked_items {
                                          ... on KontentItemAuthorizedDealers {
                                            elements {
                                              title {
                                                value
                                              }
                                              link_message {
                                                value
                                              }
                                              description {
                                                value
                                              }
                                              dealer_contacts {
                                                linked_items {
                                                  ... on KontentItemDealerContact {
                                                    elements {
                                                      dealer_name {
                                                        value
                                                      }
                                                      contact_name {
                                                        value
                                                      }
                                                      phone {
                                                        value
                                                      }
                                                      formatted_phone_number {
                                                        value
                                                      }
                                                      dealer_id {
                                                        value
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                      transaction_types {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      vehicle_types {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      kpi_plate_design_code {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      kpi_plate_foreground_color {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      plate_size {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      plate_color {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      base_price {
                                        value
                                      }
                                      redesign_price {
                                        value
                                      }
                                      govt_upgrade_price {
                                        value
                                      }
                                      captions {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      max_message_length__mm_ {
                                        value
                                      }
                                      bottom_caption_max_length__mm_ {
                                        value
                                      }
                                      message_colors {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      european_brand_name {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      feature_image_mobile {
                        name
                        value {
                          url
                        }
                      }
                      feature_image {
                        name
                        value {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      how_it_works {
        linked_items {
          ... on KontentItemCompHowItWorks {
            id
            elements {
              title {
                value
              }
              subtitle {
                value
              }
              description {
                value
              }
              learn_more_button_text {
                value
              }
              learn_more_button_url {
                value
              }
              steps {
                linked_items {
                  ... on KontentItemRichHtmlBlock {
                    elements {
                      title {
                        value
                      }
                      content {
                        value
                      }
                    }
                  }
                }
              }
              step_icons {
                value {
                  url
                }
              }
            }
          }  
        }
      }
      gifted_to_perfection {
        linked_items {
          ... on KontentItemImagecontentlist {
            __typename
            elements {
              title {
                value
              }
              description {
                value
              }
              listitem {
                linked_items {
                  ... on KontentItemImagecontentlistitem {
                    elements {
                      title {
                        value
                      }
                      description {
                        value
                      }
                      image {
                        value {
                          url
                          name
                          description
                        }
                      }
                      icon {
                        value {
                          url
                          name
                          description
                        }
                      }
                      components {
                        __typename
                        linked_items {
                          ... on KontentItemListContainer {
                            elements {
                              bottom_text {
                                value
                              }
                              items {
                                linked_items {
                                  __typename
                                  ... on KontentItemProductCategoryFormats {
                                    elements {
                                      __typename
                                      base_price {
                                        value
                                      }
                                      description {
                                        value
                                      }
                                      title {
                                        value
                                      }
                                      example_image {
                                        value {
                                          url
                                          description
                                        }
                                      }
                                      format_type {
                                        value {
                                          codename
                                          name
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          ... on KontentItemContentlist {
                            __typename
                            elements {
                              listitems {
                                linked_items {
                                  ... on KontentItemContentlistitem {
                                    __typename
                                    elements {
                                      title {
                                        value
                                      }
                                      content {
                                        value
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          ... on KontentItemFaqItem {
                            elements {
                              question {
                                value
                              }
                              answer {
                                value
                              }
                              faq_category {
                                value {
                                  name
                                  codename
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }  
    }
  }
}



`
export default GiftingLandingPage;
