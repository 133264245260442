import React from 'react'

import {
    CombinationFinderContainer,
    SimpleCombinationContentArea,
    SimpleFinderTitle,
    VehicleTypeOptions,
    VehicleTypeListItem,
    VehicleTypeItem,
    SimpleCombinationInputContainer,
    SimpleCombinationInput,
    CombinationActionContainer,
    CombinationSearchResult,
    RedesignMessage,
    SimpleCombinationFinderSegment,
    SearchResultButtonWrapperColumn,
    SimpleSearchResultButtonWrapperColumn
} from './CombinationFinder.styles'
import { List, Responsive, Grid } from 'semantic-ui-react';

import { Hyperlink, KpButton } from "@elements/index"
import theme from '@styles/theme';
import { Paragraph1, Paragraph2 } from '@styles/Global.styles';
import { CheckCombinationService } from '@services/CheckCombinationService';
import { VehicleType, VehicleTypesSetting, CombinationSettings, TransactionTypesSetting } from '@models/ProductModels';
import QuickHelpInfoLink from './QuickHelpInfo';
import { navigate } from 'gatsby';
import { GAService } from '@services/GAService';
import ReCaptcha from '@utils/reCaptcha';
import { ReCaptchaService } from '@services/ReCaptchaService';
import { GA4Event, OrderTypes, ReCaptchaThreshold } from '@utils/Constant';
import VehicleTypeSelector from './VehicleTypeSelector';

interface FinderProps {
    apiUrl: string
    vehicleType: VehicleType
    carOnly: boolean
    handleCombinationUpdate: (combination: string) => void
    handleVehicleTypeChange?: (vt: number) => void
    reCaptchaSiteKey: string
    reCaptchaSiteKeyV2: string
    darkTheme: boolean
}
interface FinderStates {
    combination: string
    combinationChecked: boolean
    combinationAvailable: boolean
    oldCombination: string
    characterCombinationCount: number
    spacingCombinationCount: number
    checkingCombination: boolean,
    isForRedesign: boolean,
    isForUpgrade: boolean,
    reCaptchaToken?: string,
    reCaptchaError: string
}

/* eslint-disable jsx-a11y/label-has-associated-control */
class EasyCombinationFinder extends React.Component<FinderProps, FinderStates>{

    constructor(props: FinderProps) {
        super(props);
        this.state = {
            combination: '',
            combinationChecked: false,
            combinationAvailable: false,
            oldCombination: '',
            characterCombinationCount: 0,
            spacingCombinationCount: 0,
            checkingCombination: false,
            isForRedesign: false,
            isForUpgrade: false,
            reCaptchaToken: undefined,
            reCaptchaError: ""
        }
    }

    chooseDesign = (iOwnThisPlate: boolean = false) =>{
        let transactionType
        if(iOwnThisPlate){
            if(this.state.isForRedesign){
                transactionType = TransactionTypesSetting.Redesign
            }
            if(this.state.isForUpgrade){
                transactionType = TransactionTypesSetting.Upgrade
            }
            GAService.trackEvent('chooseCombination', {
                combination: this.state.combination,
                orderType: "Redesign",
                restyleVSnewdesign: "Redesign",
                isAvailable: this.state.isForRedesign || this.state.isForUpgrade
            });

            GAService.trackEventGA4({
                event: GA4Event.Search,
                searchedCombination: this.state.combination,
                availableForRedesign: this.state.isForRedesign,
                availableForUpgrade: this.state.isForUpgrade,
                isAvailable: this.state.isForRedesign || this.state.isForUpgrade,
                orderType: OrderTypes.Redesign,
                selectedSuggestion: '', 
                fromSuggestion: false
            });
        }
        navigate("/select-a-style/?combination=" + this.state.combination,{
            state:{
                combination: this.state.combination,
                plateDesign: undefined,
                vehicleType: this.props.vehicleType,
                transactionType: transactionType,
                categoryName: undefined,
                isGift: undefined
        }});
    }

    handleInputChange = (event: any) => {
        this.setState({
            combination: event.target.value,
            combinationChecked: false
        });
        const { vehicleType } = this.props;
        let combination = event.target.value;

        let largeCharLength = combination.replace(CombinationSettings.largeCharPattern, "").length;
        let smallCharLength = combination.replace(CombinationSettings.smallCharPattern, "").length;
        let spaceLength = combination.replace(CombinationSettings.spacePattern, "").length;
        let alphaNumLength = largeCharLength + smallCharLength;
        let totalWidth = (largeCharLength * vehicleType.largeCharWidth) + (smallCharLength * vehicleType.smallCharWidth) + (spaceLength * vehicleType.spaceCharWidth);

        if (totalWidth <= vehicleType.maxWidth)
            this.setState({
                characterCombinationCount: alphaNumLength,
                spacingCombinationCount: spaceLength
            })

        if (alphaNumLength > vehicleType.maxChars || totalWidth > vehicleType.maxWidth) {
            this.setState({
                combination: this.state.oldCombination,
                characterCombinationCount: this.getCombinationWidth(this.state.oldCombination).largeCharacterLength + this.getCombinationWidth(this.state.oldCombination).smallCharacterLength,
            });

        } else {
            var transformedInput = combination.replace(CombinationSettings.allowed, "");
            if (transformedInput !== combination) {
                this.setState({ combination: transformedInput });
            }
            this.setState({
                oldCombination: transformedInput
            });
        }
    }

    getCombinationWidth = (combination: string) => {

        const largeCharLength = combination.replace(CombinationSettings.largeCharPattern, "").length;
        const smallCharLength = combination.replace(CombinationSettings.smallCharPattern, "").length;
        const spaceLength = combination.replace(CombinationSettings.spacePattern, "").length;
        const totalLength = (largeCharLength * this.props.vehicleType.largeCharWidth) + (smallCharLength * this.props.vehicleType.smallCharWidth) + (spaceLength * this.props.vehicleType.spaceCharWidth);

        return {
            largeCharacterLength: largeCharLength,
            smallCharacterLength: smallCharLength,
            spaceLength: spaceLength,
            totalWidth: totalLength
        }
    }

    resetCombination = () => {
        this.setState({
            combination: '',
            combinationChecked: false
        });
    }

    handleCombinationCheck = () => {
        const combination = this.state.combination.toUpperCase();
        this.checkCombinationAvailable(combination);
    }

    checkCombinationAvailable = (combination: string) => {
        this.setState({
            checkingCombination: true
        })
        CheckCombinationService.checkCombination(this.props.apiUrl, combination, this.props.vehicleType.id).then((data: any) => {
            if (data.Available) {
                this.setState({
                    combinationChecked: true,
                    combinationAvailable: true,
                    checkingCombination: false,
                    isForUpgrade: data.IsAvailableForUpgrade ? true : false,
                    isForRedesign: data.IsAvailableForRedesign ? true : false
                });
                this.props.handleCombinationUpdate(combination);
            } else {
                this.setState({
                    combinationChecked: true,
                    combinationAvailable: false,
                    checkingCombination: false ,
                    isForUpgrade: data.IsAvailableForUpgrade ? true : false,
                    isForRedesign: data.IsAvailableForRedesign ? true : false
                });
            }

            GAService.trackEvent('chooseCombination', {
                combination: combination,
                orderType: "New",
                isAvailable: data.Available,
                restyleVSnewdesign: "New Design"
            });
            GAService.trackEventGA4({
                event: GA4Event.Search,
                searchedCombination: combination,
                isAvailable: data.Available,
                availableForRedesign: null,
                availableForUpgrade: null,
                orderType: OrderTypes.New,
                selectedSuggestion: '', 
                fromSuggestion: false
            });
        })
    }

    changeVehicleType = (vehicleType: VehicleType) => {
        this.setState({
            combination: '',
            combinationChecked: false,
            checkingCombination: false,
            combinationAvailable: false,
            characterCombinationCount: 0
        });
        if (this.props.handleVehicleTypeChange !== undefined) {
            this.props.handleVehicleTypeChange(vehicleType.id);
        }
    }

    render() {
        const { combination, combinationChecked, combinationAvailable, characterCombinationCount, isForRedesign, isForUpgrade } = this.state;
        const { vehicleType, darkTheme } = this.props;
        return (
            <SimpleCombinationFinderSegment basic darkTheme={darkTheme}>
                <CombinationFinderContainer textAlign='center'>
                    <SimpleCombinationContentArea>
                        <SimpleFinderTitle textAlign='center'>Find a Plate</SimpleFinderTitle>
                        {/* <Responsive as={Paragraph1} {...Responsive.onlyMobile}>
                            Enter an available combination and scroll down to view it on the designs
                        </Responsive> */}
                        <Grid>
                            <Grid.Row centered>
                                <SimpleCombinationInputContainer mobile={15} tablet={16} computer={16} textAlign='center'>
                                    <VehicleTypeOptions>
                                        <List horizontal>
                                            <VehicleTypeListItem>
                                                <VehicleTypeItem id={"VT-" + VehicleTypesSetting.Car.name} darkTheme={darkTheme} selected={vehicleType.name === VehicleTypesSetting.Car.name} onClick={() => this.changeVehicleType(VehicleTypesSetting.Car)}>
                                                    <VehicleTypeSelector selected={vehicleType.id === VehicleTypesSetting.Car.id} darkTheme={darkTheme} vehicleType={VehicleTypesSetting.Car} characterCombinationCount={characterCombinationCount}/>
                                                </VehicleTypeItem>
                                            </VehicleTypeListItem>
                                            <VehicleTypeListItem>
                                                <VehicleTypeItem id={"VT-" + VehicleTypesSetting.Trailer.name} darkTheme={darkTheme} selected={vehicleType.name === VehicleTypesSetting.Trailer.name} onClick={() => this.changeVehicleType(VehicleTypesSetting.Trailer)}>
                                                    <VehicleTypeSelector selected={vehicleType.id === VehicleTypesSetting.Trailer.id} darkTheme={darkTheme} vehicleType={VehicleTypesSetting.Trailer} characterCombinationCount={characterCombinationCount}/>
                                                </VehicleTypeItem>
                                            </VehicleTypeListItem>
                                            <VehicleTypeListItem>
                                                <VehicleTypeItem id={"VT-" + VehicleTypesSetting.Trailer.name} darkTheme={darkTheme} selected={vehicleType.name === VehicleTypesSetting.Motorbike.name} onClick={() => this.changeVehicleType(VehicleTypesSetting.Motorbike)}>
                                                    <VehicleTypeSelector selected={vehicleType.id === VehicleTypesSetting.Motorbike.id} darkTheme={darkTheme} vehicleType={VehicleTypesSetting.Motorbike} characterCombinationCount={characterCombinationCount}/>
                                                </VehicleTypeItem>
                                            </VehicleTypeListItem>
                                        </List>
                                        <QuickHelpInfoLink darkTheme={true}/>
                                    </VehicleTypeOptions>
                                    <SimpleCombinationInput aria-label='Search Your Combination' placeholder={this.props.vehicleType.id === 1 ? 'ABC123' : 'ABC12'} spellCheck='false' autoComplete='off' autoCorrect='off' value={combination} onChange={(event) => this.handleInputChange(event)} />
                                </SimpleCombinationInputContainer>
                            </Grid.Row>
                        </Grid>
                        {
                            combinationChecked && combinationAvailable &&
                            <div>
                                <CombinationSearchResult available={true}>
                                    Combination Available!
                                </CombinationSearchResult>
                                <b>
                                    <Paragraph2 color='#707070'>
                                        Scroll down to pick your design
                                    </Paragraph2>
                                </b>
                            </div>
                        }
                        {
                            (!combinationChecked) &&
                            <CombinationActionContainer>
                                <KpButton id="scf-cc" fullWidth="mobile" loading={this.state.checkingCombination} buttonType='primary' disabled={characterCombinationCount <= 0} color={theme.brand.colors.blue} link={`/create/?combination=${combination}&vt=${vehicleType.id}`}>
                                    Check Combination
                                </KpButton>
                            </CombinationActionContainer>
                        }
                        {
                            combinationChecked && !combinationAvailable &&
                            <div>
                                <CombinationSearchResult available={false}>
                                    Combination not Available!
                                </CombinationSearchResult>

                                <CombinationActionContainer>
                                    <Responsive minWidth={theme.responsive.tablet.minWidth}>
                                        <RedesignMessage>
                                            If you already own this combination you can redesign it below
                                        </RedesignMessage>
                                    </Responsive>
                                    <Responsive maxWidth={theme.responsive.mobile.maxWidth}>
                                        <RedesignMessage>
                                            If you already own this combination you can redesign it below
                                        </RedesignMessage>
                                    </Responsive>
                                    <Grid>
                                    {(isForRedesign || isForUpgrade) ?
                                        <Grid.Row columns={2}>
                                            <SimpleSearchResultButtonWrapperColumn mobile={8} tablet={8} computer={8} largeScreen={8} widescreen={8} textAlign='center'>
                                                <KpButton id="scf-sa" fullWidth="mobile desktop" buttonType='secondary' textColor={theme.brand.colors.white} color={theme.brand.secondary} onClick={this.resetCombination}>
                                                    Start Again
                                                </KpButton>
                                            </SimpleSearchResultButtonWrapperColumn>
                                            <SimpleSearchResultButtonWrapperColumn mobile={8} tablet={8} computer={8} largeScreen={8} widescreen={8} textAlign='center'>
                                                <KpButton fullWidth='mobile desktop' color={theme.brand.colors.green} id='rcfbnp' buttonType='primary'  onClick={() => this.chooseDesign(true)}>
                                                    I Own This Plate
                                                </KpButton>
                                            </SimpleSearchResultButtonWrapperColumn>
                                        </Grid.Row>
                                        : <Grid.Row columns={1}>
                                            <SimpleSearchResultButtonWrapperColumn mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16} textAlign='center'>
                                                <KpButton id="scf-sa" fullWidth="mobile desktop" buttonType='secondary' textColor={theme.brand.colors.white} color={theme.brand.secondary} onClick={this.resetCombination}>
                                                    Start Again
                                                </KpButton>
                                            </SimpleSearchResultButtonWrapperColumn>
                                        </Grid.Row>
                                    }
                                    </Grid>
                                </CombinationActionContainer>

                            </div>
                        }
                    </SimpleCombinationContentArea>
                </CombinationFinderContainer>
            </SimpleCombinationFinderSegment>
        );
    }
}

export default EasyCombinationFinder;
