import React, { useState } from "react"
import { Grid } from "semantic-ui-react"
import {
  ImageContentListStyle,
  ImageContainerTopDescirptionStyle,
  ImageContainerTopTitle,
  ImageContainer,
  FirstItemRow,
  FirstItemLeftColumn,
  FirstItemRightColumn,
} from "./ImageContentList.styles"
import { ImageContentListType } from "./types"
import { mapToImageContentItem } from "./mapper"
import ImagecontentListItem from "./ImageContentListItem"
import { getDefaultCombination } from "@utils/Helpers"
import { VehicleTypesSetting } from "@models/ProductModels"
import { SimpleCombinationFinder } from "@components/CombinationFinder"
import { GiftContainer, GiftGrid, GiftRow, GiftTopRow, GridRightColumnStyle, ImageContentDescription, ImageContentSubTitle } from "./ImageContentListItem.styles"
import { ImageContentListItemComponent } from "./ImageContentListItemComponent"
import EasyCombinationFinder from "@components/CombinationFinder/EasyCombinationFinder"

export const ImageContentList = (props: ImageContentListType) => {
  const [combination, setCombination] = useState("");
  const [isVehicleTypeChanged, setIsVehicleTypeChanged] = useState(false);
  const [vehicleType, setVehicleType] = useState({
    name: 'Car',
    id: 1,
    letters: 6,
    largeCharWidth: 55,
    smallCharWidth: 27.5,
    spaceCharWidth: 13.75,
    maxChars: 6,
    maxWidth: 330
  });

  const { title, description, listitem, apiUrl, carOnly, reCaptchaSiteKey, reCaptchaSiteKeyV2 } = props
  const firstItemdata = mapToImageContentItem(listitem[0].elements, 0)

  const handleCombinationUpdate = (combination: string) => {
      setCombination(combination);
    }
  const getVehicleTypeById = (vt: number) => {
    return VehicleTypesSetting.Motorbike.id === vt ?
      VehicleTypesSetting.Motorbike : (
        VehicleTypesSetting.Trailer.id === vt ?
          VehicleTypesSetting.Trailer :
          VehicleTypesSetting.Car
      );
  }
  const handleVehicleTypeChange = (vt: number) => {
    const vehicleTypeNew = getVehicleTypeById(vt);
    const defaultCombination = getDefaultCombination(vehicleType)
    if (vehicleTypeNew.id != vehicleType.id) {
      setIsVehicleTypeChanged(true);      
    } else {
      setIsVehicleTypeChanged(false);
    }
    setVehicleType(vehicleTypeNew);
    setCombination(defaultCombination);
    //this.setState({ vehicleType: vehicleType, combination: defaultCombination });
  }

  const getInnerText = (description: string): string => {
    let descriptionInner = description
      .replaceAll("<br>", "")
      .replaceAll("<p>", "")
      .replaceAll("</p>", "")
    return descriptionInner
  }
  
  return (
    <>
      <ImageContainer>
        <ImageContainerTopTitle>{title}</ImageContainerTopTitle>
        <ImageContainerTopDescirptionStyle
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </ImageContainer>
      <Grid stackable>
        <FirstItemRow>        
          <FirstItemLeftColumn width={8}>          
            <GiftContainer textAlign="center">              
              <GiftGrid textAlign="center">
                <GiftTopRow>
                  <img src={firstItemdata.image[0].url} alt={firstItemdata.image[0].description} />
                </GiftTopRow>
                <GiftRow>     
                  <GridRightColumnStyle width={16}>                
                    <ImageContentSubTitle><img src={firstItemdata.icon[0].url} alt={firstItemdata.icon[0].description} />{firstItemdata.title}</ImageContentSubTitle> 
                    {getInnerText(firstItemdata.description) == "" ? (
                      ""
                    ) : (
                      <ImageContentDescription
                        dangerouslySetInnerHTML={{ __html: firstItemdata.description }}
                      />
                    )}
    
                    <ImageContentListItemComponent
                      item={firstItemdata.components}
                      backgroundColor='#fff'
                    />
                  </GridRightColumnStyle>              
                </GiftRow>
              </GiftGrid>
            </GiftContainer>
            
          </FirstItemLeftColumn>
          <FirstItemRightColumn width={8}  style={{ marginLeft: "-14px !important;",  marginRight: "-14px !important;" }}>
            <EasyCombinationFinder apiUrl={apiUrl} handleCombinationUpdate={handleCombinationUpdate} handleVehicleTypeChange={handleVehicleTypeChange} 
          vehicleType={vehicleType} carOnly={false} reCaptchaSiteKey={reCaptchaSiteKey} reCaptchaSiteKeyV2={reCaptchaSiteKeyV2} darkTheme={false} />
          </FirstItemRightColumn>
        </FirstItemRow>
        
        {listitem.map((item, index: number) => {
          
          const data = mapToImageContentItem(item.elements, index)
          if(index >0) {
            return (
              <ImageContentListStyle key={index}>
                <ImagecontentListItem
                  item={data}
                  backgroundColor='#fff'
                />
              </ImageContentListStyle>
            )
          }
          
        })}
      </Grid>
    </>
  )
}
